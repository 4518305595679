import PermissionsPage from './PermissionsPage';
import UsersPage from './UsersPage';

const UserRoutePage: Page = {
  path: '/users',
  title: 'User Management',
  icon: 'user',
  type: 'Route',
  pages: [UsersPage, PermissionsPage],
};

export default UserRoutePage;
