import LinePage from './lines/LinePage';
import MachinePage from './machines/MachinePage';

const MachineRoutePage: Page = {
  path: '/machines',
  title: 'Machine Data',
  type: 'Route',
  pages: [MachinePage, LinePage],
};

export default MachineRoutePage;
