import { AnchorButton, Button, Card, ControlGroup, Divider, InputGroup, Navbar } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../../../stores';
import TableComponent from '../../elements/Table';
import generateRows from '../../elements/Table/generateRows';
import useForeignKey from '../../hooks/useForeignKey';
import useGet from '../../hooks/useGet';

interface Props {
  model: Model,
  addUrl?: string,
  customAction?: boolean,
  onClick?: (id: string | Generic) => void,
}

const Table: React.FC<Props> = ({ model, addUrl, customAction, onClick }) => {
  const [search, setSearch] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const { serverUrl } = useTypedSelector(s => s.basis);
  
  const url = `${serverUrl}/${model.name}`;
  const [data, get, getLoading] = useGet<Generic[]>({ url });
  const { fks } = useForeignKey(model, data);

  const searchableFields = model.fields.filter(f => f.searchable).map(f => f.name);

  const headers = [
    '#',
    ...model
      .fields
      .filter(v => !v.hideColumn)
      .filter(v => v.type !== 'array' && v.type !== 'model')
      .map(v => v.label),
  ];

  const filtered = data == null ? [] : data.filter(d => {
    if (searchableFields.length === 0) {
      return true;
    }

    for (let i = 0; i < searchableFields.length; i++) {
      const fieldName = searchableFields[i];
      const value = d[fieldName]?.toString().toLowerCase();
      if (value?.indexOf(search.toLowerCase()) > -1) {
        return true;
      }
    }

    return false;
  });

  const rows = generateRows(model, filtered, fks);

  return (
    <Card style={{ padding: '0 0 20px' }}>
      <Navbar>
        <Navbar.Group>
          <ControlGroup>
            {searchableFields.length > 0 && (
              <>
                <InputGroup
                  round
                  leftIcon="search"
                  placeholder="Search"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />

                <Divider />
              </>
            )}

            {customAction && addUrl && (
              <AnchorButton
                minimal
                href={`#${location.pathname}/${addUrl}`}
                icon="plus"
                text="Add"
              />
            )}

            {!customAction && (
              <AnchorButton
              minimal
              href={`#${location.pathname}/add`}
              icon="plus"
              text="Add"
            />
            )}
          </ControlGroup>
        </Navbar.Group>

        <Navbar.Group align="right">
          <ControlGroup>
            <Button
              minimal
              icon="refresh"
              loading={getLoading}
              text="Refresh"
              onClick={() => get()}
            />
          </ControlGroup>
        </Navbar.Group>
      </Navbar>

      <TableComponent
        loading={getLoading}
        headers={headers}
        rows={rows}
        onClick={idx => {
          if (data == null) {
            return;
          }

          if (customAction && typeof onClick === 'function') {
            if (filtered[idx]._id == null) {
              onClick(filtered[idx]);
            } else {
              onClick(filtered[idx]._id);
            }
          } else if (!customAction) {
            navigate(`${location.pathname}/${filtered[idx]._id}`);
          }
        }}
      />
    </Card>
  );
};

export default Table;
