import { NonIdealState } from '@blueprintjs/core';
import React from 'react';

const NotFound: React.FC = () => (
  <NonIdealState
    icon="tractor"
    title="404"
    description="Page Not Found"
  />
);

export default NotFound;
