import React, { useState } from 'react';
import Form, { createObj } from '../../basis/components/elements/Form';
import FormBase from '../../basis/components/layouts/FormBase';
import UserModel from './User';

interface Props {
  readOnly?: boolean,
}

const defaultValue = createObj(UserModel.fields);

const UserPageComp: React.FC<Props> = ({ readOnly }) => {
  const [form, setForm] = useState(defaultValue);

  return (
    <FormBase
      form={form}
      model={UserModel}
      readOnly={readOnly}
      onDataFetched={(data: any) => {
        if (data.hasOwnProperty('register')) {
          data['register'] = `${window.location.host}/#${data['register']}`;
        }
        setForm(data);
      }}
    >
      <Form
        inline
        fields={UserModel.fields}
        readOnly={readOnly}
        values={form}
        onChange={f => setForm(f)}
      />
    </FormBase>
  );
};

export default UserPageComp;
