import React from 'react';
import { useTypedSelector } from '../../../../stores';
import useGet from '../../hooks/useGet';
import Suggest from '../Suggest';

interface Props {
  disabled?: boolean,
  field: Field,
  foreignKey: ForeignKey,
  readOnly?: boolean,
  value: string,
  values: any,
  onChange: (value: any) => void,
}

interface Generic {
  _id: string,
  [key: string]: string,
}

const InputSuggest: React.FC<Props> = ({ disabled, foreignKey, readOnly, value, values, onChange }) => {
  const { serverUrl } = useTypedSelector(s => s.basis);

  const url = `${serverUrl}/${foreignKey.model}`;
  const [data, , loading] = useGet({ url });

  const items = data == null ? [] : (data as Generic[])
    .filter(f => {
      if (foreignKey.filter == null) {
        return true;
      }

      return values[foreignKey.filter] === f[foreignKey.filter];
    })
    .map(d => ({ id: d._id, name: d[foreignKey.field] }))

  return (
    <Suggest
      disabled={disabled || readOnly}
      readOnly={readOnly}
      loading={loading}
      selectedId={value}
      items={items}
      onSelect={(item) => onChange(item?.id)}
    />
  );
};

export default InputSuggest;
