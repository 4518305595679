import PermissionModel from './Permission';

const PermissionsPage: Page = {
  path: '/permissions',
  icon: 'hat',
  title: 'Permissions',
  type: 'CRUDTable',
  model: PermissionModel,
};

export default PermissionsPage;