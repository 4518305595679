import { Classes, Colors, FocusStyleManager, Toaster } from '@blueprintjs/core';
import React, { useEffect } from 'react';
import useThemeDetector from './components/hooks/useThemeDetector';
import Loading from './components/fragments/Loading';
import Routes from './routes';
import { useTypedDispatch, useTypedSelector } from '../stores';
import { getApp, setRoutes, setServerUrl, setSidebar } from './reducers/basis';
import { setDarkMode } from './reducers/ui';

interface Props {
  routes: Route[],
  serverUrl: string,
  sidebar: SidebarMenu[],
}

FocusStyleManager.onlyShowFocusOnTabs();

const AppToast = Toaster.create();

const BasisApp: React.FC<Props> = ({ routes, serverUrl, sidebar }) => {
  const dispatch = useTypedDispatch();
  const systemTheme = useThemeDetector();
  const { app, loading } = useTypedSelector(s => s.basis);
  const { dark, toast } = useTypedSelector(s => s.ui);

  useEffect(() => {
    if (app.title != null) {
      window.document.title = app.title;
    }
  }, [app.title]);
  
  useEffect(() => {
    dispatch(setRoutes(routes));
  }, [dispatch, routes]);

  useEffect(() => {
    dispatch(setServerUrl(serverUrl));
  }, [dispatch, serverUrl]);

  useEffect(() => {
    dispatch(setSidebar(sidebar))
  }, [dispatch, sidebar]);

  useEffect(() => {
    window.document.body.style.backgroundColor = dark ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY4;
  }, [dark]);

  useEffect(() => {
    dispatch(getApp());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setDarkMode(systemTheme));
  }, [dispatch, systemTheme]);

  useEffect(() => {
    if (toast.message !== '') {
      AppToast.show({ timeout: 3000, ...toast });
    }
  }, [toast]);

  return (
    <div className={dark ? Classes.DARK : ''}>
      {loading && (
        <div style={{ marginTop: 96 }}>
          <Loading />
        </div>
      )}

      {!loading && <Routes />}
    </div>
  );
};

export default BasisApp;
