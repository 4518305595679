import axios from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../stores';
import { setMessage } from '../../reducers/ui';
import { getBearerHeader } from '../../utils/services';

type CallbackFn<T> = (data: T) => void;
type Fn<T> = (url: string, callback: CallbackFn<T>) => void;

const useGetUrl = <T>(): [Fn<T>, boolean] => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { token } = useTypedSelector(s => s.user).user;

  const get = useCallback(async (url: string, callback: CallbackFn<T>) => {
    setLoading(true);
    try {
      const res = await axios.get(url, { headers: getBearerHeader(token) });
      if (res.status === 200) {
        callback(res.data.data as T);
      } else {
        dispatch(setMessage({
          intent: 'danger',
          message: `${res.status} ${res.statusText}. ${res.data.message}`,
        }));
      }
    } catch (err) {
      const message = axios.isAxiosError(err) ? err.message : 'An unexpected error occurred.';
      dispatch(setMessage({ intent: 'danger', message }));
    }
    setLoading(false);
  }, [dispatch, token]);

  return [get, loading];
};

export default useGetUrl;
