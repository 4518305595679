import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BasisState {
  app: App,
  loading: boolean,
  loadingCount: number,
  routes: Route[],
  serverUrl: string,
  sidebar: SidebarMenu[],
}

const initialState: BasisState = {
  app: {
    id: '',
    package: '',
    title: '',
    initialize: true,
  },
  loading: false,
  loadingCount: 0,
  routes: [],
  serverUrl: 'http://localhost:8000',
  sidebar: [],
};

export const basisSlice = createSlice({
  name: 'basis',
  initialState,
  reducers: {
    setApp: (state, { payload }: PayloadAction<App>) => {
      state.app = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      const loadingCount = state.loadingCount + (payload ? 1 : -1);
      state.loadingCount = loadingCount;
      state.loading = loadingCount > 0;
    },
    setRoutes: (state, { payload }: PayloadAction<Route[]>) => {
      state.routes = payload;
    },
    setServerUrl: (state, { payload }: PayloadAction<string>) => {
      state.serverUrl = payload;
    },
    setSidebar: (state, { payload }: PayloadAction<SidebarMenu[]>) => {
      state.sidebar = payload;
    },
  },
});
