const PageModel: Model = {
  name: 'pages',
  title: 'Page',
  description: 'List of pages in application',
  fields: [
    { label: 'Title', name: 'title', type: 'string' },
    { label: 'Icon', name: 'icon', type: 'icon' },
    { label: 'Parent', name: 'parent', type: 'string' },
    { label: 'Path', name: 'path', type: 'string' },
    { label: 'Type', name: 'type', type: 'enum', enum: ['CRUDTable', 'Table', 'Form'] },
  ],
};

const PagesPage: Page = {
  icon: 'document',
  path: '/dev/pages',
  title: 'Pages',
  type: 'CRUDTable',
  model: PageModel,
};

export default PagesPage;
