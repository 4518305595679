import { FormGroup } from '@blueprintjs/core';
import React from 'react';
import InputArray from './InputArray';
import InputField from './InputField';

interface Props {
  disabled?: boolean,
  fields: Field[],
  inline?: boolean,
  readOnly?: boolean,
  values: any,
  onChange: (values: any) => void,
}

const Form: React.FC<Props> = ({ disabled, fields, inline, readOnly, values, onChange }) => {
  const filterOnlyInput = (f: Field) => f.type !== 'array' && f.type !== 'model';
  const filterAutoIncrement = (f: Field) => !(!readOnly && f.type === 'auto_increment');
  const filterOnlyArray = (f: Field) => f.type === 'array';
  const filterOmitEmpty = (f: Field) => {
    if (f.omitEmpty == null || !f.omitEmpty) {
      return true;
    }

    return values[f.name] != null;
  };

  const inputFields = fields.filter(filterAutoIncrement).filter(filterOnlyInput).filter(filterOmitEmpty);
  const arrayFields = fields.filter(filterOnlyArray).filter(filterOmitEmpty);

  return (
    <>
      {inputFields.map((field, i) => {
        if (values == null || field.name == null) {
          return null;
        }

        return (
          <FormGroup
            className={inline ? 'form-inline' : ''}
            inline={inline}
            key={`${i}_${field.name}`}
            label={field.label}
          >
            <InputField
              disabled={disabled}
              field={field}
              readOnly={readOnly}
              values={values}
              value={values[field.name]}
              onChange={(key, value) => onChange({ ...values, [key]: value })}
            />
          </FormGroup>
        );
      })}

      {arrayFields.map((field, i) => {
        return (
          <InputArray
            key={`${i}_${field.name}`}
            field={field}
            readOnly={readOnly}
            value={values[field.name]}
            onChange={(key, value) => onChange({ ...values, [key]: value })}
          />
        );
      })}
    </>
  );
};

export default Form;
