import { Button, Colors, Divider, H6, Icon, IconName } from '@blueprintjs/core';
import React from 'react';
import { useTypedSelector } from '../../../../stores';
import styles from './sidebar.module.css';

interface Props {
  icon?: IconName,
  text: string,
  onClick?: () => void,
}

const Section: React.FC<Props> = ({ icon, text, onClick }) => {
  const { sidebar } = useTypedSelector(s => s.ui);

  const isCollapsed = sidebar === 'collapse';

  return (
    <>
      <Divider style={{ margin: '-1px 0 0' }} />

      <div className={styles.section}>
        {isCollapsed ? null : <H6>{text}</H6>}

        {icon != null && (
          <Button
            minimal
            small
            disabled={typeof onClick !== 'function'}
            icon={<Icon icon={icon} size={14} color={Colors.GRAY2} />}
            onClick={onClick}
          />
        )}
      </div>

      <Divider style={{ margin: 0 }} />
    </>
  );
};

export default Section;
