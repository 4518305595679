import React from 'react';
import Table from '../../../basis/components/layouts/Table';

const DailyReportView: React.FC = () => {
  return (
    <Table
      customAction
      model={{
        name: 'machines/lwt810/daily',
        title: 'Daily Report',
        fields: [
          { name: 'date', type: 'string', label: 'Date', searchable: true },
          { name: 'machine', type: 'string', label: 'Machine', searchable: true },
          { name: 'rpm', type: 'number', label: 'RPM' },
          { name: 'tension', type: 'number', label: 'Tension (kgf)' },
          { name: 'speed', type: 'number', label: 'Speed (yard/minute)' },
          { name: 'efficiency', type: 'number', label: 'Efficiency (%)' },
          { name: 'output', type: 'number', label: 'Cloth Length (yard)' },
        ],
      }}
    />
  );
};

export default DailyReportView;
