import { Card, Classes, Colors, Divider, FormGroup, H4, InputGroup, NonIdealState, Tag, Text } from '@blueprintjs/core';
import { Serie } from '@nivo/line';
import { format } from 'date-fns';
import React from 'react';
import CircleProgressBar from '../../../basis/components/elements/CircleProgressBar';
import LineChart from './LineChart';

interface Props {
  machine: Machine,
  values: LWT810ValueAggr[],
  tickValues?: string,
}

const Layout: React.FC<{ children?: React.ReactNode, machine: Machine }> = ({ children, machine }) => {
  return (
    <Card>
      <H4>Machine {machine.no}</H4>
      <Divider style={{ margin: '20px -20px' }} />
      {children}
    </Card>
  );
};

const CardMachine: React.FC<Props> = ({ machine, values, tickValues }) => {
  if (values.length === 0) {
    return (
      <Layout machine={machine}>
        <NonIdealState
          icon="cargo-ship"
          description="No data from machine in the last 60 minutes"
        />
      </Layout>
    );
  }

  const sorted = values.sort((a, b)=> (new Date(b.timestamp)).getTime() - (new Date(a.timestamp)).getTime());
  const lastValue = sorted[0];

  const rpmSeries: Serie[] = [{
    id: 'rpm',
    color: Colors.BLUE3,
    data: sorted.map(value => ({ x: new Date(value.timestamp), y: value.dataMachine.rpm }))
  }];

  const tensionSeries: Serie[] = [{
    id: 'tension',
    color: Colors.BLUE3,
    data: sorted.map(value => ({ x: new Date(value.timestamp), y: value.dataMachine.tension.value }))
  }];

  const efficiencySeries: Serie[] = [{
    id: 'efficiency',
    color: Colors.BLUE3,
    data: sorted.map(value => ({ x: new Date(value.timestamp), y: value.dataShift.efficiencyShift }))
  }];

  return (
    <Card>
      <H4>Machine - {machine.no}</H4>
      <Divider style={{ margin: '20px -20px' }} />
      
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 300px', gap: 40 }}>
        <div>
          <Text className="section-title">Production</Text>

          <FormGroup inline className="form-inline" label="Run Time">
            <InputGroup
              readOnly
              value={lastValue.dataShift.runTime}
            />
          </FormGroup>

          <FormGroup inline className="form-inline" label="Cloth Length">
            <InputGroup
              readOnly
              value={lastValue.dataShift.clothLength.value.toString()}
              rightElement={<Tag minimal>yard</Tag>}
            />
          </FormGroup>

          <FormGroup inline className="form-inline" label="Cut Length">
            <InputGroup
              readOnly
              value={lastValue.dataShift.cutLength.value.toString()}
              rightElement={<Tag minimal>yard</Tag>}
            />
          </FormGroup>

          <FormGroup inline className="form-inline" label="Speed Per Min">
            <InputGroup
              readOnly
              value={lastValue.dataShift.productionPerMin.value.toString()}
              rightElement={<Tag minimal>yard</Tag>}
            />
          </FormGroup>
        </div>

        <div>
          <Text className="section-title">Machine</Text>

          <FormGroup inline className="form-inline" label="RPM">
            <InputGroup readOnly value={lastValue.dataMachine.rpm.toString()} />
          </FormGroup>

          <FormGroup inline className="form-inline" label="Angle">
            <InputGroup readOnly value={lastValue.dataMachine.angle.toString()} />
          </FormGroup>

          <FormGroup inline className="form-inline" label="Tension">
            <InputGroup
              readOnly
              value={lastValue.dataMachine.tension.value.toString()}
              rightElement={<Tag minimal>{lastValue.dataMachine.tension.uom}</Tag>}
            />
          </FormGroup>

          <FormGroup inline className="form-inline" label="Efficiency (Daily)">
            <InputGroup
              readOnly
              value={lastValue.dataShift.efficiencyDaily.toString()}
              rightElement={<Tag minimal>%</Tag>}
            />
          </FormGroup>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text className="section-title">Efficiency (Shift)</Text>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
            <CircleProgressBar percentage={lastValue.dataShift.efficiencyShift} />
          </div>
        </div>
      </div>

      <Text
        className={Classes.TEXT_MUTED}
        style={{ fontSize: '0.8rem', marginBottom: -10, marginTop: 10, textAlign: 'right' }}
      >
        <i>
          Last Update: {format(new Date(lastValue.timestamp), 'yyyy-MM-dd hh:mm')}
        </i>
      </Text>

      <Divider style={{ margin: '20px -20px' }} />

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }}>
        <div>
          <Text className="section-title">RPM</Text>
          <LineChart data={rpmSeries} tickValues={tickValues} />
        </div>

        <div>
          <Text className="section-title">Tension ({lastValue.dataMachine.tension.uom})</Text>
          <LineChart data={tensionSeries} tickValues={tickValues} />
        </div>

        <div>
          <Text className="section-title">Efficiency - Shift (%)</Text>
          <LineChart data={efficiencySeries} tickValues={tickValues} />
        </div>
      </div>
    </Card>
  );
};

export default CardMachine;
