import { MaybeElement } from '@blueprintjs/core';
import React from 'react';

interface HeaderContextValue {
  content: MaybeElement,
  setContent: (content: MaybeElement) => void,
}

const HeaderContext = React.createContext<HeaderContextValue>({
  content: null,
  setContent: () => {},
});

export default HeaderContext;
