import React from 'react';
import BasisApp from './basis/BasisApp';
import { routes } from './routes';
import { sidebar } from './sidebars';

const serverUrl = 'https://dpl-basis-b27ccws5oq-et.a.run.app';
// const serverUrl = 'http://localhost:8080';

const App: React.FC = () => {
  return (
    <BasisApp
      routes={routes}
      serverUrl={serverUrl}
      sidebar={sidebar}
    />
  );
};

export default App;
