import { format, parse, sub } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetUrl from '../../../basis/components/hooks/useGetUrl';
import { useTypedSelector } from '../../../stores';
import CardMachine from '../../dashboard/views/CardMachine';

function getShiftTime(date: string, shift: string) {
  const shift1 = '05:30:10';
  const shift2 = '13:30:10';
  const shift3 = '21:30:10';
  
  let startTime = '00:00:00';
  let endTime = '23:59:59';

  switch (shift) {
    case '1':
      startTime = shift1;
      endTime = shift2;
      break;
    case '2':
      startTime = shift2;
      endTime = shift3;
      break;
    case '3':
      startTime = shift3;
      endTime = shift1;
      break;
  }

  const end = parse(`${date} ${endTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());

  const startDate = shift === '3' ? format(sub(end, { days: 1 }), 'yyyy-MM-dd') : date;
  const start = parse(`${startDate} ${startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());

  return { start, end };
}

const ProductionDetailView: React.FC = () => {
  const [values, setValues] = useState<LWT810ValueAggr[]>([]);

  const { machine, date, shift } = useParams();
  const { serverUrl } = useTypedSelector(s => s.basis);
  const [getValues] = useGetUrl<LWT810ValueAggr[]>();

  useEffect(() => {
    if (date == null || shift == null || machine == null) {
      return;
    }

    const { start, end } = getShiftTime(date, shift);

    const url = `${serverUrl}/machines/lwt810/values`;
    getValues(
      `${url}?from=${start.toISOString()}&to=${end.toISOString()}&machine=${machine}`,
      data => setValues(data)
    );
  }, [date, getValues, machine, serverUrl, shift]);

  return (
    <CardMachine
      machine={{ no: `${machine} | Shift ${shift}`, ip: '', line: '', type: '' }}
      values={values}
      tickValues="every 1 hour"
    />
  );
};

export default ProductionDetailView;
