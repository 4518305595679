import { Colors, Text } from '@blueprintjs/core';
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTypedSelector } from '../../../../stores';
import styles from './circleprogress.module.css';

interface Props {
  percentage: number,
  label?: string,
}

const CircleProgressBar: React.FC<Props> = ({ percentage, label }) => {
  const { dark } = useTypedSelector(s => s.ui);

  const percen = isNaN(percentage) ? 0 : percentage;

  return (
    <div className={styles.root}>
      <div style={{ textAlign: 'center' }}>
        <CircularProgressbar
          strokeWidth={10}
          value={percen}
          text={`${percen}%`}
          styles={buildStyles({
            textSize: "18px",
            strokeLinecap: "butt",
            textColor: dark ? Colors.LIGHT_GRAY4 : Colors.DARK_GRAY2,
            pathColor: dark ? Colors.FOREST4 : Colors.FOREST3,
            trailColor: dark ? Colors.GRAY1 : Colors.LIGHT_GRAY2,
          })}
        />
      </div>
      <Text>{label}</Text>
    </div>
  );
};

export default CircleProgressBar;
