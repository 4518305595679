import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../basis/components/layouts/Table';

const ShiftReportView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Table
      customAction
      onClick={(data) => {
        if (typeof data === 'object') {
          navigate(`/productions/detail/${data.machine}/${data.date}/${data.shift}`);
        }
      }}
      model={{
        name: 'machines/lwt810/shift',
        title: 'Daily Report',
        fields: [
          { name: 'date', type: 'string', label: 'Date', searchable: true },
          { name: 'shift', type: 'string', label: 'Shift', searchable: true },
          { name: 'machine', type: 'string', label: 'Machine', searchable: true },
          { name: 'rpm', type: 'number', label: 'RPM' },
          { name: 'tension', type: 'number', label: 'Tension (kgf)' },
          { name: 'speed', type: 'number', label: 'Speed (yard/minute)' },
          { name: 'efficiency', type: 'number', label: 'Efficiency (%)' },
          { name: 'output', type: 'number', label: 'Cloth Length (yard)' },
        ],
      }}
    />
  );
};

export default ShiftReportView;
