import React from 'react';
import { ButtonGroup, Button, Icon } from '@blueprintjs/core';
import styles from './pagination.module.css';

interface Props {
  className?: string,
  index: number,
  maxButton: number,
  onChange: (index: number) => void,
  pageCount: number,
  style?: React.CSSProperties,
}

const Pagination: React.FunctionComponent<Props> = (props: Props) => {
  const { className, index, maxButton, onChange, pageCount, style } = props;

  const changePage = (idx: number) => {
    if (idx > pageCount - 1) {
      onChange(pageCount - 1);
      return;
    }

    if (idx < 0) {
      onChange(0);
      return;
    }

    onChange(idx);
  };

  const renderPaginationButtons = () => {
    let start = Math.floor(index / maxButton) * maxButton;
    let end = start + maxButton;

    if (end > (pageCount - 1)) {
      start = pageCount - maxButton;
      start = start < 0 ? 0 : start;
      end = pageCount;
    }

    const buttons = [];
    for (let i = start; i < end; i += 1) {
      buttons.push(
        <Button active={i === index} key={i} minimal onClick={() => changePage(i)}>{i + 1}</Button>,
      );
    }

    return buttons;
  };

  return (
    <ButtonGroup className={[styles.root, className].filter(Boolean).join(' ')} style={style}>

      <Button disabled={index <= 0} minimal onClick={() => changePage(index - maxButton)}>
        <Icon icon="double-chevron-left" />
      </Button>
      <Button disabled={index <= 0} minimal onClick={() => changePage(index - 1)}>
        <Icon icon="chevron-left" />
      </Button>

      { renderPaginationButtons() }

      <Button disabled={index >= pageCount - 1} minimal onClick={() => changePage(index + 1)}>
        <Icon icon="chevron-right" />
      </Button>
      <Button
        disabled={index >= pageCount - 1}
        minimal
        onClick={() => changePage(index + maxButton)}
      >
        <Icon icon="double-chevron-right" />
      </Button>

    </ButtonGroup>
  );
};

export default Pagination;
