import React from 'react';
import { Button, Classes, Dialog, IconName, Intent } from '@blueprintjs/core';
import { useTypedSelector } from '../../../../stores';

interface Props {
  cancelLabel?: string,
  children?: React.ReactNode,
  confirmIntent?: Intent,
  confirmLabel?: string,
  icon?: IconName,
  isOpen: boolean,
  loading?: boolean,
  message?: string,
  title?: string,
  onCancel?: () => void,
  onConfirm?: () => void,
}

const ConfirmDialog: React.FC<Props> = (props: Props) => {
  const {
    cancelLabel,
    children,
    confirmIntent,
    confirmLabel,
    icon,
    isOpen,
    loading,
    message,
    onCancel,
    onConfirm,
    title,
  } = props;

  const { dark } = useTypedSelector(s => s.ui);

  return (
    <Dialog
      canEscapeKeyClose={!loading}
      canOutsideClickClose={false}
      className={dark ? Classes.DARK : ''}
      isCloseButtonShown={false}
      icon={icon}
      isOpen={isOpen}
      onClose={onCancel}
      title={title || 'Confirm'}
    >
      <div className={Classes.DIALOG_BODY}>
        {message != null ? message : children}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button minimal disabled={loading} onClick={onCancel}>
            {cancelLabel || 'Cancel'}
          </Button>

          <Button
            intent={confirmIntent || 'primary'}
            loading={loading}
            onClick={onConfirm}
            style={{ minWidth: 60 }}
          >
            {confirmLabel || 'Yes'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
