const UserModel: Model = {
  name: 'users',
  title: 'User',
  description: 'User list to access application',
  fields: [
    { label: 'Name', name: 'name', type: 'string', searchable: true },
    { label: 'Email', name: 'email', type: 'string', searchable: true },
    { label: 'Developer', name: 'dev', type: 'boolean' },
    {
      label: 'Register URL',
      name: 'register',
      type: 'string',
      omitEmpty: true,
      readOnly: true,
      hideColumn: true,
    },
  ]
};

export default UserModel;
