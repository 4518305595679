import DailyReportView from './views/DailyReportView';

const ReportPage: Page = {
  path: '/',
  title: 'Daily',
  type: 'ReactNode',
  component: DailyReportView,
};

export default ReportPage;
