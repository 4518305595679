import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUser } from '../../basis/utils/storage';

interface UserState {
  user: User,
  loading: boolean,
}

const initialState: UserState = {
  user: {
    dev: false,
    email: '',
    id: '',
    name: '',
    token: '',
  },
  loading: false,
};

const getInitialState = (): UserState => {
  const user = getUser();
  if (user == null) {
    return initialState;
  }
  return { ...initialState, user };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});
