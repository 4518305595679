import { add } from 'date-fns';
import React, { useEffect, useState } from 'react';
import useGet from '../../../basis/components/hooks/useGet';
import useGetUrl from '../../../basis/components/hooks/useGetUrl';
import useInterval from '../../../basis/components/hooks/useInterval';
import { useTypedSelector } from '../../../stores';
import CardMachine from './CardMachine';

const intervalMin = 60;

const DashboardView: React.FC = () => {
  const [values, setValues] = useState<LWT810ValueAggr[]>([]);

  const { serverUrl } = useTypedSelector(s => s.basis);
  const [machines] = useGet<Machine[]>({ url: `${serverUrl}/machines` });
  const [getValues] = useGetUrl<LWT810ValueAggr[]>();
  
  useInterval(() => {
    const url = `${serverUrl}/machines/lwt810/values`;

    const from = add(new Date(), { minutes: -intervalMin }).toISOString();
    const to = (new Date()).toISOString();

    getValues(`${url}?from=${from}&to=${to}`, data => setValues(data));
  }, 10 * 1000);

  useEffect(() => {
    const url = `${serverUrl}/machines/lwt810/values`;

    const from = add(new Date(), { minutes: -intervalMin }).toISOString();
    const to = (new Date()).toISOString();

    getValues(`${url}?from=${from}&to=${to}`, data => setValues(data));
  }, [getValues, serverUrl]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {machines != null && machines.map(machine => {
        const vals = values.filter(value => value.machine.no === machine.no);

        return (
          <CardMachine
            key={machine.no}
            machine={machine}
            values={vals}
          />
        );
      })}
    </div>
  );
};

export default DashboardView;
