import ShiftReportView from './views/ShiftReportView';

const ReportShiftPage: Page = {
  path: '/shift',
  title: 'Shift',
  type: 'ReactNode',
  component: ShiftReportView,
};

export default ReportShiftPage;
