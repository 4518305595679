import { Colors } from '@blueprintjs/core';
import { Theme } from '@nivo/core';
import { ResponsiveLine, Serie } from '@nivo/line';
import React from 'react';
import { useTypedSelector } from '../../../stores';
import Tooltip from './Tooltip';

interface Props {
  data: Serie[],
  tickValues?: string,
}

const LineChart: React.FC<Props> = ({ data, tickValues }) => {
  const { dark } = useTypedSelector(s => s.ui);

  const theme: Theme = {
    textColor: dark ? Colors.LIGHT_GRAY4 : Colors.DARK_GRAY2,
    grid: {
      line: {
        stroke: dark ? Colors.DARK_GRAY5 : Colors.LIGHT_GRAY2,
      }
    }
  };

  let max = 0;
  data[0].data.forEach(v => {
    if (v.y as number > max) {
      max = v.y as number;
    }
  });

  return (
    <div style={{ height: 200 }}>
      <ResponsiveLine
        isInteractive
        useMesh
        axisBottom={{
          format: "%H:%M",
          tickValues: tickValues == null ? 'every 10 minute' : tickValues,
        }}
        colors={{ datum: 'color' }}
        curve="monotoneX"
        data={data}
        enableGridX={false}
        enablePoints={false}
        margin={{ bottom: 40, left: 40, right: 20, top: 20 }}
        theme={theme}
        tooltip={Tooltip}
        xScale={{ type: 'time', format: '%H:%M:%S', precision: 'second' }}
        yScale={{ type: 'linear', max: max * 1.1 }}
        yFormat=">-.2f"
      />
    </div>
  );
};

export default LineChart;
