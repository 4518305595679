import axios from 'axios';
import { useCallback, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../stores';
import { setMessage } from '../../reducers/ui';
import { getBearerHeader } from '../../utils/services';

interface Request {
  url: string,
  method: 'post' | 'put' | 'delete',
  callback?: () => void,
}

const usePost = <T>({ url, method, callback }: Request): [(payload?: T) => void, boolean] => {
  const [loading, setLoading] = useState(false);

  const dispatch = useTypedDispatch();
  const { token } = useTypedSelector(s => s.user).user;

  const post = useCallback(async <T>(payload: T) => {
    setLoading(true);
    try {
      let res;
      if (method === 'delete') {
        res = await axios[method](url, { headers: getBearerHeader(token) });
      } else {
        res = await axios[method](url, payload, { headers: getBearerHeader(token) });
      }

      if (res.status !== 200) {
        dispatch(setMessage({
          intent: 'danger',
          message: `${res.status} ${res.statusText}. ${res.data.message}`,
        }));
      } else if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      const message = axios.isAxiosError(err) ? err.message : 'An unexpected error occurred.';
      dispatch(setMessage({ intent: 'danger', message }));
    }
    setLoading(false);
  }, [callback, dispatch, method, token, url]);

  return [post, loading];
};

export default usePost;
