import React from 'react';
import { Button } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props {
  disabled?: boolean,
  onClick: () => void,
  show: boolean,
}

const LockButton: React.FC<Props> = ({ disabled, show, onClick }) => (
  <Tooltip2 content={`${show ? 'Hide' : 'Show'} Password`}>
    <Button
      minimal
      disabled={disabled}
      icon={show ? 'eye-open' : 'eye-off'}
      intent="primary"
      onClick={onClick}
    />
  </Tooltip2>
);

export default LockButton;
