import DashboardPage from './modules/dashboard/DashboardPage';
import DatabasesPage from './modules/dev/databases';
import PagesPage from './modules/dev/pages';
import SidebarPage from './modules/dev/sidebar';
import MachineRoutePage from './modules/machines/MachineRoutePage';
import Production from './modules/production';
import ProductionDetailPage from './modules/productionDetail';
import UserRoutePage from './modules/users';

export const routes: Route[] = [
  { href: '/', page: DashboardPage },
  { href: '/productions', page: Production },
  { href: '/productions/detail/:machine/:date/:shift', page: ProductionDetailPage },
  { href: '/machines', page: MachineRoutePage },
  { href: '/users', page: UserRoutePage },
  { href: '/dev/databases', page: DatabasesPage },
  { href: '/dev/sidebar', page: SidebarPage },
  { href: '/dev/pages', page: PagesPage },
];
