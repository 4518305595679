import Line from './Line';

const LinePage: Page = {
  path: '/lines',
  title: 'Production Lines',
  type: 'CRUDTable',
  model: Line,
};

export default LinePage;
