import Line from '../lines/Line';

const Machine: Model = {
  name: 'machines',
  title: 'Machines',
  fields: [
    { name: 'no', label: 'Machine No.', type: 'string' },
    { name: 'type', label: 'Type', type: 'enum', enum: ['Toyota LWT810'] },
    { name: 'line', label: 'Line', type: 'foreign_key', foreignKey: { model: Line.name, field: 'name' } },
    { name: 'ip', label: 'IP Address', type: 'string' },
  ],
};

export default Machine;
