import { Button, Card, Classes, Dialog, Divider, H5, Tag, Text } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useTypedSelector } from '../../../../stores';
import useForeignKey from '../../hooks/useForeignKey';
import Table from '../Table';
import generateRows from '../Table/generateRows';
import Form from './Form';
import styles from './form.module.css';
import { createObj } from './utils';

interface Props {
  field: Field,
  readOnly?: boolean,
  value: any[],
  onChange?: (key: string, value: any) => void,
}

const InputArray: React.FC<Props> = ({ field, readOnly, value, onChange }) => {
  const [addForm, setAddForm] = useState<any>();
  const [showAdd, setShowAdd] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const { fks } = useForeignKey(field.model, value);
  const { dark } = useTypedSelector(s => s.ui);

  return (
    <div key={field.name} className={styles.inputArray}>
      <div>
        <H5 className="section-title">
          <span>{field.label}</span>
          <Tag round intent="primary">{Array.isArray(value) ? value.length : 0}</Tag>
        </H5>

        {!readOnly && (
          <>
            <Divider />
            <Button
              minimal
              icon="plus"
              text="Add"
              onClick={() => setShowAdd(true)}
            />
          </>
        )}
      </div>

      {Array.isArray(value) && value.length === 0 && (
        <Card style={{ padding: 10 }}>
          <Text className={Classes.TEXT_MUTED}>No {field.label.toLowerCase()}</Text>
        </Card>
      )}

      {Array.isArray(value) && value.length > 0 && typeof field.model === 'object' && (
        <Table
          headers={[
            '#',
            ...field.model.fields
              .filter(v => !v.hideColumn)
              .filter(v => v.type !== 'array' && v.type !== 'model')
              .map(v => v.label),
          ]}
          rows={generateRows(field.model, value, fks).map((values, i) => {
            return [...values, (!readOnly && (
              <Button
                minimal
                icon="trash"
                onClick={() => {
                  if (typeof onChange === 'function') {
                    const newVal = [...value];
                    newVal.splice(i, 1);
                    onChange(field.name, newVal);
                  }
                }}
              />
            ))]
          })}
          onClick={idx => {
            setAddForm(value[idx]);
            setShowInfo(true);
          }}
        />
      )}

      {Array.isArray(value) && value.length > 0 && typeof field.model !== 'object' && value.map((v, i) => (
        <Card key={i} className={styles.list}>
          <div>
            {typeof field.model !== 'object' && <div>{v}</div>}

            {!readOnly && (
              <div>
                <Button
                  minimal
                  icon="trash"
                  onClick={() => {
                    if (typeof onChange === 'function') {
                      const newVal = [...value];
                      newVal.splice(i, 1);
                      onChange(field.name, newVal);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </Card>
      ))}

      <Dialog
        className={dark ? Classes.DARK : ''}
        icon="info-sign"
        isOpen={showInfo}
        title={`${field.label}`}
        onClose={() => setShowInfo(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <Form
            inline
            readOnly
            fields={typeof field.model === 'object' ? field.model.fields : [field]}
            values={addForm}
            onChange={() => null}
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent="primary"
              text="Close"
              onClick={() => setShowInfo(false)}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        className={dark ? Classes.DARK : ''}
        icon="plus"
        isOpen={showAdd}
        title={`Add ${field.label}`}
        onClose={() => setShowAdd(false)}
        onOpening={() => {
          if (typeof field.model === 'object') {
            setAddForm(createObj(field.model.fields));
          } else {
            setAddForm('');
          }
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <Form
            inline
            fields={typeof field.model === 'object' ? field.model.fields : [field]}
            values={addForm}
            onChange={form => setAddForm(form)}
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              minimal
              text="Cancel"
              onClick={() => setShowAdd(false)}
            />

            <Button
              intent="primary"
              text="Add"
              onClick={() => {
                if (typeof onChange === 'function') {
                  if (Array.isArray(value)) {
                    onChange(field.name, [...value, addForm]);
                  } else {
                    onChange(field.name, [addForm]);
                  }
                }
                setShowAdd(false);
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default InputArray;
