import { NonIdealState, Spinner } from '@blueprintjs/core';
import React from 'react';

const Loading: React.FC = () => (
  <NonIdealState
    icon={<Spinner intent="primary" />}
    description="Loading..."
  />
);

export default Loading;
