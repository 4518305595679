import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../stores';
import { setMessage } from '../../reducers/ui';
import { getBearerHeader } from '../../utils/services';

type Fn = () => void;

interface Request {
  url: string,
  manualCall?: boolean,
}

const useGet = <T>({ url, manualCall }: Request): [T | null, Fn, boolean] => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useTypedDispatch();
  const { token } = useTypedSelector(s => s.user).user;

  const get = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(url, { headers: getBearerHeader(token) });
      if (res.status === 200) {
        setData(res.data.data as T);
      } else {
        dispatch(setMessage({
          intent: 'danger',
          message: `${res.status} ${res.statusText}. ${res.data.message}`,
        }));
      }
    } catch (err) {
      const message = axios.isAxiosError(err) ? err.message : 'An unexpected error occurred.';
      dispatch(setMessage({ intent: 'danger', message }));
    }
    setLoading(false);
  }, [dispatch, token, url]);

  useEffect(() => {
    if (manualCall == null || !manualCall) {
      get();
    }
  }, [get, manualCall]);

  return [data, get, loading];
};

export default useGet;
