const FieldModel: Model = {
  name: 'fields',
  title: 'Fields',
  description: '',
  fields: [
    { label: 'Name', name: 'name', type: 'string' },
    { label: 'Label', name: 'label', type: 'string' },
    { label: 'Type', name: 'type', type: 'enum', enum: ['string', 'number', 'boolean'] }
  ],
}

const DatabaseModel: Model = {
  name: 'databases',
  title: 'Database',
  description: 'Application database registry',
  fields: [
    { label: 'Database', name: 'name', type: 'string' },
    { label: 'Title', name: 'title', type: 'string' },
    { label: 'Description', name: 'description', type: 'string' },
    { label: 'Fields', name: 'fields', type: 'array', model: FieldModel },
  ],
};

const DatabasesPage: Page = {
  icon: 'database',
  path: '/dev/databases',
  title: 'Database',
  type: 'CRUDTable',
  model: DatabaseModel,
};

export default DatabasesPage;
