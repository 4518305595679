import UserModel from './User';
import UserPageComp from './UserPageComp';

const UserTablePage: Page = {
  path: '/',
  title: 'Users',
  type: 'Table',
  model: UserModel,
};

const UserAddPage: Page = {
  path: '/add',
  title: 'Add User',
  type: 'Form',
  model: UserModel,
};

const UserPage: Page = {
  path: '/:id',
  title: 'User',
  type: 'ReactNode',
  component: () => <UserPageComp readOnly />,
};

const UserEditPage: Page = {
  path: '/:id/edit',
  title: 'User',
  type: 'ReactNode',
  component: () => <UserPageComp />,
};

const UsersPage: Page = {
  path: '/',
  icon: 'user',
  title: 'Users',
  type: 'Route',
  pages: [UserTablePage, UserAddPage, UserPage, UserEditPage],
};

export default UsersPage;
