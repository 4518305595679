import React, { lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../stores';
import { load } from './load';
import ProtectedRoutes from './ProtectedRoutes';

const Login = load(lazy(() => import('../pages/Login')));
const Register = load(lazy(() => import('../pages/Register')));
const Setup = load(lazy(() => import('../pages/Setup')));

const AppRoutes: React.FC = () =>  {
  const { app } = useTypedSelector(s => s.basis);
  const location = useLocation();

  if (!app.initialize && location.pathname !== '/setup') {
    return <Navigate replace to="/setup" />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register/:id" element={<Register />} />
      <Route path="/setup" element={<Setup />} />
      <Route path="*" element={<ProtectedRoutes />} />
    </Routes>
)};

export default AppRoutes;