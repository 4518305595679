import { AnchorButton, Icon, MaybeElement } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';
import { useLocation } from 'react-router';
import { useTypedSelector } from '../../../../stores';
import styles from './sidebar.module.css';

interface Props {
  href: string,
  icon: IconName | MaybeElement,
  text: string,
}

const Menu: React.FC<Props> = ({ href, icon, text }) => {
  const location = useLocation();
  const { sidebar } = useTypedSelector(s => s.ui);

  const active = `/${location.pathname.split('/')[1]}` === href?.substring(0, href.length);

  return (
    <AnchorButton
      fill
      large
      minimal={!active}
      className={styles.a}
      href={`#${href}`}
      icon={<Icon icon={icon} />}
      intent={active ? 'primary' : 'none'}
      text={sidebar === 'collapse' ? <span>&nbsp;</span> : text}
    />
  );
};

export default Menu;
