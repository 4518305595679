const SidebarModel: Model = {
  title: 'Sidebar',
  name: 'sidebar',
  description: '',
  fields: [
    { label: 'Section', name: 'section', type: 'enum', enum: ['main', 'settings'] },
    { label: 'Text', name: 'text', type: 'string' },
    { label: 'Icon', name: 'icon', type: 'icon' },
    { label: 'href', name: 'href', type: 'string' },
  ],
};

const SidebarPage: Page = {
  path: '/dev/sidebar',
  title: 'Sidebar',
  type: 'CRUDTable',
  model: SidebarModel,
};

export default SidebarPage;
