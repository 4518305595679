import { NavigateFunction } from 'react-router-dom';
import { setLoading, setUser } from '.';
import { AppThunk } from '..';
import { setMessage } from '../../basis/reducers/ui';
import UserService from '../../basis/services/user';
import { saveUser } from '../../basis/utils/storage';

export function login(navigate: NavigateFunction, form: LoginForm): AppThunk {
  return async (dispatch, getState) => {
    const { email, password, remember } = form;

    dispatch(setLoading(true));
    try {
      const { data } = await UserService.login(getState().basis.serverUrl, email, password);
      if (remember) {
        saveUser(data.data);
      }
      dispatch(setUser(data.data));
      dispatch(setLoading(false));
      navigate('/');
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}