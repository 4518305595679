import DashboardView from './views/DashboardView';

const DashboardPage: Page = {
  path: '/',
  title: 'Dashboard',
  type: 'ReactNode',
  component: DashboardView,
};

export default DashboardPage;
