import { Button, Menu, Navbar } from '@blueprintjs/core';
import { MenuItem2, Popover2 } from '@blueprintjs/popover2';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import logo from '../../../../assets/logo.png';
import logoWhite from '../../../../assets/logo-white.png';
import logoOnly from '../../../../assets/logo-only.png';
import { RootState, useTypedDispatch } from '../../../../stores';
import { setUser } from '../../../../stores/user';
import { clearLocalStorage } from '../../../utils/storage';
import NoNotif from '../../elements/NoNotif';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HeaderContext from './HeaderContext';
import styles from './header.module.css';

interface Props {
  className?: string,
  collapse?: boolean,
}

const Header: React.FC<Props> = ({ className, collapse }) => {
  const { content } = useContext(HeaderContext);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { dark } = useSelector((s: RootState) => s.ui);
  const { name } = useSelector((s: RootState) => s.user).user;
  const { width } = useWindowDimensions();

  const logout = useCallback(() => {
    dispatch(setUser({ dev: false, email: '', id: '', name: '', token: '' }));
    clearLocalStorage();
    navigate('/login');
  }, [dispatch, navigate]);

  return (
    <div className={className}>
      <Navbar>
        {
          (width > 767 || (width <= 767 && content == null)) &&
          <Navbar.Group
            className={collapse ? styles.collapse : styles.expand}
            style={{ marginLeft: collapse ? -10 : 0, marginRight: collapse ? 12 : 0 }}
          >
            <img
              className={styles.logo}
              height={collapse ? 28 : 42}
              src={collapse ? logoOnly : (dark ? logoWhite : logo)}
              alt="PT Diatera Cipta Teknologi"
            />
          </Navbar.Group>
        }

        <Navbar.Group>
          {content}
        </Navbar.Group>

        { 
          width > 767 &&
          <>
            <Navbar.Group align="right">
              <Popover2
                className={styles.desktop}
                content={<NoNotif />}
                interactionKind="click"
                placement="bottom-end"
                renderTarget={({ isOpen, ref, ...targetProps }) => (
                  <Button {...targetProps} minimal elementRef={ref!} icon="notifications" />
                )}
              />
              <Navbar.Divider className={styles.desktop} />

              <Popover2
                className={styles.desktop}
                content={
                  <Menu>
                    <MenuItem2 icon="user" text="Profile" onClick={()=>navigate('/profile')} />
                    <MenuItem2 icon="log-out" text="Log out" onClick={logout} />
                  </Menu>
                }
                interactionKind="click"
                placement="bottom-end"
                renderTarget={({ isOpen, ref, ...targetProps }) => (
                  <Button {...targetProps} minimal elementRef={ref!} icon="user" text={name} />
                )}
              />
            </Navbar.Group>
          </>
        }
      </Navbar>
    </div>
  );
};

export default Header;
