import Table from '../../../basis/components/layouts/Table';
import Machine from './Machine';

const MachineTablePage: Page = {
  path: '/',
  title: 'Users',
  type: 'ReactNode',
  component: () => <Table model={Machine} customAction />,
};

const MachinePage: Page = {
  path: '/',
  title: 'Machines',
  type: 'Route',
  pages: [MachineTablePage],
};

export default MachinePage;
