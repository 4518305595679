import { MaybeElement, Tag } from '@blueprintjs/core';
import { format } from 'date-fns';
import { ReactNode } from 'react';

const generateRows = (model: Model, data?: any[], fks?: Fks[]): (string | ReactNode)[][] => {
  return data == null ? [] : data.map((values, i) => {
    const res: (string | MaybeElement)[] = [];
    model.fields
      .filter(v => v.type !== 'array' && v.type !== 'model' && !v.hideColumn)
      .forEach(field => {
        if (field.type === 'foreign_key' && field.foreignKey != null && fks != null && values[field.name] != null) {
          fks.forEach(fk => {
            if (fk._id === values[field.name] && field.foreignKey != null) {
              res.push(fk.value[field.foreignKey.field]?.toString());
            }
          });
          return;
        }

        if (field.type === 'date' && values[field.name] != null) {
          res.push(format(new Date(values[field.name]), 'yyyy-MM-dd'));
          return;
        }

        switch (typeof values[field.name]) {
          case 'boolean':
            res.push(
              <Tag
                round
                icon={values[field.name] ? 'small-tick' : 'small-cross'}
                intent={values[field.name] ? 'primary' : 'warning'}
                minimal={!values[field.name]}
                style={{ padding: 2 }}
              />
            );
            return;
          case 'number':
            res.push(values[field.name].toLocaleString());
            return;
          case 'object':
            res.push('');
            return;
          case 'undefined':
            res.push('');
            return;
          default:
            res.push(values[field.name].toString());
        }
      });

    return [`${i+1}`, ...res];
  });
};

export default generateRows;
