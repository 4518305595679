import { Card, Divider, Tag } from '@blueprintjs/core';
import { PointTooltipProps } from '@nivo/line';
import { format } from 'date-fns';
import React from 'react';
import styles from './tooltip.module.css';

const Tooltip: React.FC<PointTooltipProps> = ({ point }) => (
  <Card elevation={3} className={styles.root}>
    <div className={styles.header}>
      <Tag style={{ minWidth: 12, minHeight: 12, backgroundColor: point.serieColor }} /> 
      <span>{point.serieId}</span>
    </div>
    <Divider className={styles.divider} />
    <div className={styles.values}>
      <span>Time</span>
      <strong>: {format(new Date(point.data.x.toString()), 'HH:mm')}</strong>
    </div>
    <div className={styles.values}>
      <span>Value</span>
      <strong>: {point.data.y.toString()}</strong>
    </div>
  </Card>
);

export default Tooltip;
