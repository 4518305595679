import React, { useState } from 'react';
import { HTMLInputProps, InputGroup, InputGroupProps2 } from '@blueprintjs/core';
import LockButton from '../LockButton';

declare type Props = HTMLInputProps & InputGroupProps2;

const InputPassword: React.FC<Props> = (props: Props) => {
  const [show, setShow] = useState(false);
  const { disabled } = props;

  return (
    <InputGroup
      {...props}
      type={show ? 'text' : 'password'}
      rightElement={
        <LockButton disabled={disabled} onClick={() => setShow(!show)} show={show} />
      }
    />
  );
};

export default InputPassword;
