import axios, { AxiosResponse } from 'axios';
import { getBasicHeader } from '../utils/services';

function login(serverUrl: string, email: string, password: string): Promise<AxiosResponse> {
  const url = `${serverUrl}/login`
  const token = window.btoa(`${email}:${password}`);
  return axios.post(url, {}, { headers: getBasicHeader(token) });
}

const UserService = {
  login,
};

export default UserService;
