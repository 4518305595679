import { Card, Divider, Switch } from '@blueprintjs/core';
import React from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../stores';
import { setDarkMode } from '../../../reducers/ui';
import Menu from './Menu';
import Section from './Section';
import styles from './sidebar.module.css';

interface SidebarProps {
  collapsed?: boolean,
  menus: SidebarMenu[],
  onCollapse: () => void,
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed, menus, onCollapse }) => {
  const dispatch = useTypedDispatch();
  const { dark, sidebar } = useTypedSelector(s => s.ui);
  const { dev } = useTypedSelector(s => s.user).user;

  const isCollapsed = sidebar === 'collapse';

  return (
    <Card className={styles.root} elevation={1}>
      <Section
        icon={isCollapsed ? 'double-chevron-right' : 'double-chevron-left'}
        text="Navigation"
        onClick={onCollapse}
      />

      <div className={styles.menu}>
        {/* Main */}
        {menus.filter(m => m.section === 'main').map(menu => (
          <div key={menu.href}>
            <Menu
              key={menu.href}
              href={menu.href}
              icon={menu.icon}
              text={menu.text}
            />
          </div>
        ))}

        {/* Settings */}
        <Section text="Settings" icon="cog" />
        {menus.filter(m => m.section === 'settings').map(menu => (
          <div key={menu.href}>
            <Menu
              key={menu.href}
              href={menu.href}
              icon={menu.icon}
              text={menu.text}
            />
          </div>
        ))}

        {/* Developers */}
        {dev && (<Section text="Developer" icon="lab-test" />)}
        {dev && menus.filter(m => m.section === 'developer').map(menu => (
          <div key={menu.href}>
            <Menu
              key={menu.href}
              href={menu.href}
              icon={menu.icon}
              text={menu.text}
            />
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <Divider style={{ margin: '0 -16px 16px' }} />

        <Switch
          alignIndicator={!collapsed ? 'right' : undefined}
          checked={dark}
          label={!collapsed ? 'Dark mode' : undefined}
          onChange={() => dispatch(setDarkMode(!dark))}
        />
      </div>
    </Card>
  );
};

export default Sidebar;
