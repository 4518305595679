import { HTMLSelect, InputGroup, NumericInput, Switch, TextArea } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { add, format } from 'date-fns';
import React from 'react';
import InputPassword from '../InputPassword';
import InputSuggest from './InputSuggest';

interface Props {
  disabled?: boolean,
  field: Field,
  readOnly?: boolean,
  value?: any,
  values: any[],
  onChange?: (key: string, value: any) => void,
}

const InputField: React.FC<Props> = ({ disabled, field, readOnly, value, values, onChange }) => {
  switch (field.type) {
    case 'boolean':
      return (
        <Switch
          checked={value}
          disabled={disabled || field.readOnly || readOnly}
          readOnly={field.readOnly || readOnly}
          onChange={() => {
            if (typeof onChange === 'function') {
              onChange(field.name, !value);
            }
          }}
        />
      );
    case 'date':
      return (
        <DateInput
          disabled={disabled || field.readOnly || readOnly}
          formatDate={date => format(date, 'yyyy-MM-dd')}
          inputProps={{ readOnly: field.readOnly || readOnly }}
          maxDate={add(new Date(`${new Date().getFullYear()}-12-31`), { years: 3 })}
          minDate={add(new Date(`${new Date().getFullYear()}-01-01`), { years: -80 })}
          value={value == null ? null : new Date(value)}
          parseDate={str => new Date(str)}
          onChange={date => {
            if (typeof onChange === 'function') {
              onChange(field.name, date);
            }
          }}
        />
      );
    case 'enum':
      return (
        <HTMLSelect
          fill
          disabled={disabled || field.readOnly || readOnly}
          style={{ textTransform: 'capitalize' }}
          value={value}
          onChange={e => {
            if (typeof onChange === 'function') {
              onChange(field.name, e.target.value);
            }
          }}
        >
          <option>Select one</option>
          {field.enum?.map(e => (
            <option key={e} value={e} style={{ textTransform: 'capitalize' }}>{e}</option>)
          )}
        </HTMLSelect>
      );
    case 'foreign_key':
      if (field.foreignKey == null) {
        return (
          <InputGroup
            disabled
            value="Error: No foreignKey field"
          />
        );
      }

      return (
        <InputSuggest
          disabled={disabled}
          field={field}
          foreignKey={field.foreignKey}
          readOnly={readOnly || field.readOnly}
          value={value}
          values={values}
          onChange={v => {
            if (typeof onChange === 'function') {
              onChange(field.name, v);
            }
          }}
        />
      );
    case 'number':
      return (
        <NumericInput
          disabled={disabled}
          readOnly={field.readOnly || readOnly}
          value={value}
          onValueChange={(n, s) => {
            if (typeof onChange === 'function' && !isNaN(parseInt(s))) {
              onChange(field.name, n);
            }
          }}
        />
      );
    case 'password':
      return (
        <InputPassword
          readOnly={readOnly}
          disabled={disabled || field.readOnly}
          value={value}
          onChange={e => {
            if (typeof onChange === 'function') {
              onChange(field.name, e.target.value);
            }
          }}
        />
      );
    case 'text':
      return (
        <TextArea
          fill
          disabled={disabled}
          readOnly={readOnly || field.readOnly}
          rows={3}
          value={value}
          onChange={e => {
            if (typeof onChange === 'function') {
              onChange(field.name, e.target.value);
            }
          }}
        />
      );
    default:
      return (
        <InputGroup
          disabled={disabled}
          readOnly={readOnly || field.readOnly}
          value={value}
          onChange={e => {
            if (typeof onChange === 'function') {
              onChange(field.name, e.target.value);
            }
          }}
        />
      );
  }
};

export default InputField;
