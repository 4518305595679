import { useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from '../../../stores';
import useGetUrl from './useGetUrl';

const useForeignKey = (model?: Model | FieldType, data?: any) => {
  const [fks, setFks] = useState<Fks[]>([]);

  const { serverUrl } = useTypedSelector(s => s.basis);
  const [getFkItems] = useGetUrl<Generic>();

  const getFkData = useCallback((val: any) => {
    if (model == null || typeof model !== 'object') {
      return;
    }

    model.fields.forEach(field => {
      if (field.type !== 'foreign_key' || field.foreignKey == null || val[field.name] == null) {
        return;
      }

      const url = `${serverUrl}/${field.foreignKey.model}/${val[field.name]}`;
      getFkItems(url, (data) => {
        setFks((fks) => {
          const newFks = [...fks];
  
          if (field.foreignKey == null) {
            return newFks;
          }
  
          const thisFk = newFks.find(f => f._id === val[field.name]);
  
          if (thisFk == null) {
            newFks.push({
              model: field.foreignKey.model,
              _id: val[field.name].toString(),
              value: data,
            })
          }
  
          return newFks;
        });
      });
    });
  }, [getFkItems, model, serverUrl]);

  useEffect(() => {
    if (data == null) {
      return;
    }

    if (Array.isArray(data)) {
      data.forEach(val => {
        getFkData(val);
      });
    } else {
      getFkData(data);
    }
  }, [data, getFkData]);

  return { fks };
};

export default useForeignKey;
