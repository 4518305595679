export function createObj(fields: Field[]): Object {
  const entries = fields.map(v => {
    switch (v.type) {
      case 'auto_increment': return [v.name, 'idx_auto_increment'];
      case 'boolean': return [v.name, !!v.defaultValue];
      case 'date':
        if (v.defaultValue == null) {
          return [v.name, null];
        }
        return [v.name, new Date(v.defaultValue as string)];
      case 'number':
        if (v.defaultValue == null || isNaN(parseFloat(v.defaultValue.toString()))) {
          return [v.name, 0];
        }
        return [v.name, parseFloat(v.defaultValue.toString())];
      case 'array':
        if (typeof v.model === 'object' && v.model.fields.length > 0) {
          if (v.defaultValue == null || !Array.isArray(v.defaultValue)) {
            return [v.name, []];
          }
          return [v.name, v.defaultValue];
        }
        return [v.name, []];
      default:
        return [v.name, v.defaultValue == null ? '' : v.defaultValue];
    }
  });

  return Object.fromEntries(entries);
}
