import ProductionDetailView from './views/ProductionDetailView';

const ProductionDetailPage: Page = {
  path: '/',
  title: 'Production Detail',
  type: 'ReactNode',
  component: ProductionDetailView,
};

export default ProductionDetailPage;
