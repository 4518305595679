import ReportPage from './ReportPage';
import ReportShiftPage from './ReportShiftPage';

const ProductionPage: Page = {
  path: '/productions',
  title: 'Production',
  type: 'Route',
  pages: [ReportPage, ReportShiftPage],
};

export default ProductionPage;
