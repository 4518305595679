import { NonIdealState } from '@blueprintjs/core';
import React from 'react';
import styles from './nonotif.module.css';

const NoNotif: React.FC = () => (
  <div className={styles.root}>
    <NonIdealState
      icon="notifications"
      title="No new notification!"
      description="Check this section for updates."
    />
  </div>
);

export default NoNotif;
