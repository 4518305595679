import React from 'react';
import { Button, Card, Divider, H3, IconName, MaybeElement } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import styles from './whitecard.module.css';

type Fn = () => void;

interface WhiteCardProps {
  actionOnClick?: () => void,
  actionIcon?: IconName | MaybeElement,
  back?: string | Fn,
  children?: React.ReactNode,
  className?: string,
  loading?: boolean,
  title?: string | React.ReactNode,
  tools?: React.ReactNode,
}

const WhiteCard: React.FC<WhiteCardProps> = (props: WhiteCardProps) => {
  const { actionOnClick, actionIcon, back, children, className, loading, title, tools } = props;
  const navigate = useNavigate();
  const classes = [styles.container, className].filter(Boolean).join(' ');

  const onBackClick = () => {
    switch (typeof back) {
      case 'function': back(); break;
      case 'string': navigate(back); break;
      default: navigate(-1); break;
    }
  };

  return (
    <div className={styles.root}>
      <Card className={classes} elevation={3}>
        {title && (
          <>
            <H3>
              <Button large minimal icon="chevron-left" onClick={onBackClick} />

              <span>{title}</span>

              {tools}

              {
                actionIcon
                  ? <Button large minimal disabled={loading} icon={actionIcon} onClick={actionOnClick} />
                  : null
              }
            </H3>
            <Divider className={styles.divider} />
          </>
        )}

        {children}
      </Card>
    </div>
  );
};

export default WhiteCard;
